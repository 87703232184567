import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import "./App.css";

const pageContent = {
  "/": {
    title: "Привет!",
    text: `Временами мне становится одиноко и страшно, накрывает бессилие и появляется чувство, что совершенно ничего невозможно  сделать.
      Но я решила поделиться тем, о чём я сейчас думаю. Тут мои размышления о том, как наше прошлое отражается в настоящем.`,
    nextPages: ["/place", "/empty_ideals"],
    emojies: ['🤝', '🤨'],
    class_name: 'pink'
  },
  "/why": {
    title: "Цветы, свечи и мягкие игрушки",
    video: "https://2023art.online/videos/d205bee8c0b461dbe26666fe54ad1def.mp4",
    text: "Статья 31 Конституции РФ гласит: «Граждане Российской Федерации имеют право собираться мирно без оружия, проводить собрания, митинги и демонстрации, шествия и пикетирование». Полиция реагирует на цветочные мемориалы как на протесты. Публичное поминовение становится формой гражданского неповиновения. Почему?",
    nextPages: ["/ussrtorf", "/memorial"],
    emojies: ['💔', '🤔'],
  },
  "/place": {
    title: "«Эзопов язык» публичной памяти",
    video: "https://2023art.online/videos/626fdcbcf392cf1487f9abbc284f71a8.mp4",
    text: "Жители России несут цветы и игрушки к разным памятникам, чаще всего - к памятникам жертвам политических репрессий. «То, что люди не могут сказать плакатом, они говорят цветами и выбором места памяти», — пишет антрополог Александра Архипова.",
    nextPages: ["/why", "/scale"],
    emojies: ['🥀', '🗿'],
  },
  "/scale": {
    title: "Оценка исторического масштаба",
    video: "https://2023art.online/videos/6808a1e35b7391ecbec344d33780c582.mp4",
    text: `Репрессии за позицию по СВО* - самые массовые в современной России. По мнению историков, по строгости приговоры инакомыслящим в современной России уже сопоставимы с СССР 1960-х, а по масштабу сегодняшние репрессии можно сравнить с временами позднего СССР.

      *Роскомнадзор настаивает, что специальная военная операция России в Украине не является «нападением, вторжением либо объявлением войны»`,
    nextPages: ["/discredit", "/why"],
    emojies: ['😮', '🗿'],
  },
  "/constitution": {
    title: "18 статья Конституции РФ",
    video: "https://2023art.online/videos/6ea4839ceff81a511aa3a22db6ba7867.mp4",
    text: "Права и свободы человека и гражданина являются непосредственно действующими. Они определяют смысл, содержание и применение законов, деятельность законодательной и исполнительной власти, местного самоуправления и обеспечиваются правосудием.",
    nextPages: ["/final", "/rake"],
    emojies: ['🤝', '🤨'],
  },
  "/memorial": {
    title: "*Организация признана иноагентом в России и ликвидирована решением суда",
    video: "https://2023art.online/videos/320514a60634e227b593924249413d6a.mp4",
    text: `СК возбудил дело о реабилитации нацизма в отношении сотрудников «Мемориала»*, следователи провели серию обысков. «Мемориал»* занимался исследованием репрессий в СССР, реабилитацией жертв и помощью их семьям. Организацией собраны базы данных "Жертвы политического террора в СССР", "Расстрелы в Москве" и другие. В 2020 году электронный банк данных жертв политических репрессий содержал более 3,25 млн справок о репрессированных.

    Основными целями «Мемориала»* являлись содействие развитию гражданского общества и построение демократического правового государства, недопущение возврата к тоталитаризму, а также распространение демократических ценностей.`,
    nextPages: ["/victims", "/stalin"],
    emojies: ['😥', '🤨'],
  },
  "/ussrtorf": {
    title: "Исторический масштаб",
    video: "https://2023art.online/videos/72ca73b087931ec570522708a71d7eba.mp4",
    text: `Министр МВД говорит о том, что предоставленный 4 марта 2022 дополнительный инструментарий позволил своевременно изолировать тех, кто раскачивает ситуацию. За «дискредитацию Вооружённых Сил» и «недостоверные сведения об их использовании» пресечено 4500 административных правонарушений, заведено свыше 100 уголовных дел.

    Численность населения России примерно вдвое меньше, чем была в СССР. В 1970-е годы приговоров по антисоветским статьям было несколько десятков каждый год. Статья №70 «антисоветская агитация и пропаганда» и №190 «распространение заведомо ложных измышлений, порочащих советский государственный и общественный строй».`,
    nextPages: ["/memorial", "/victims"],
    emojies: ['👁', '🥺'],
  },
  "/discredit": {
    title: "Признаки «дискредитации ВС РФ»",
    video: "https://2023art.online/videos/1c6928d351eda2a29981ce9b3a0a5b1a.mp4",
    text: `14 марта 2022 Кировский районный суд Уфы назначил штраф Елене Богдановой за акцию с книгой «Война и мир».

    29 июля 2022 суд в Вологде оштрафовал Ивана Беликова на 30 тысяч рублей за пикет с чистым листом бумаги.

    Жительницу Череповца Августину Сентюлеву также оштрафовали на 30 тысяч за плакат с шестой заповедью «Не убий».`,
    nextPages: ["/ussrtorf", "/rake"],
    emojies: ['😥', '🤐'],
  },
  "/rake": {
    title: "Кто сказал, что нельзя наступать на грабли",
    video: "https://2023art.online/videos/3c0b34232eb2f4ad1e57a0f18d6fcbf6.mp4",
    text: `Отсутствие критического взгляда на себя ведёт к повторению. Не был пройден процесс, в ходе которого было бы достигнуто понимание истории. Мы по-прежнему находимся в ситуации  нерешённости и недоговренности по многим вопросам.`,
    nextPages: ["/final", "/justifying_repression"],
    emojies: ['😬', '🗿'],
  },
  "/stalin": {
    title: "Телеграмма Сталина 17 сентября 1941 года",
    video: "https://2023art.online/videos/7b517175420d46bdba8f1a32755bfbc1.mp4",
    text: `23 марта 2023 года. Заседание рабочей группы Военно-промышленной комиссии РФ по контролю за производством востребованных образцов вооружения, военной, специальный техники и средств поражения.`,
    nextPages: ["/essential", "/victims"],
    emojies: ['🤨', '👀'],
  },
  "/victims": {
    title: "Тезис о советской политической системе",
    video: "https://2023art.online/videos/3293affd4a9deef8c7448469b72ad596.mp4",
    text: `«Жертвами незаконного и незаслуженного преследования со стороны государства с 1918 по 1953 год стали в общей сложности не менее 24,5 млн человек (из них не менее 5 млн были убиты или доведены до преждевременной смерти)», — пишет Николай Эппле в книге «Неудобное прошлое. Память о государственных преступлениях в России и других странах»`,
    nextPages: ["/essential", "/kgb"],
    emojies: ['💔', '🗿'],
  },
  "/doublethink": {
    title: "А что я один мог сделать?",
    video: "https://www.youtube.com/embed/_ksSk5ySgKY?&autoplay=1",
    text: `Тезис о советской политической системе из книги «Неудобное прошлое»: «Советская действительность во всех сферах держалась на лжи и двоемыслии, возведенных в систему. При декларируемом народовластии, верховенстве закона, равенстве возможностей, свободе слова, вероисповедания, собраний, в реальности имела место очевидная для всех имитация этих принципов. Это порождало пассивность и безответственность на всех уровнях, цинизм и недоверие граждан по отношению к государству и друг к другу».`,
    nextPages: ["/victims", "/discredit"],
    emojies: ['🗿', '😮'],
  },
  "/interests": {
    title: "Общественное мнение",
    video: "https://2023art.online/videos/c646842da4e55876eba488deb7167f6f.mp4",
    text: `Людмила Алексеева, одна из основательниц Московской Хельсинкской группы: «В течение многих веков во всех странах  интересы отдельной личности были не на первом месте. Потом кое-где граждане смогли изменить ситуацию. Но в большом количестве стран по-прежнему человек существует для государства, а не государство для человека, - в том числе, увы, и в нашей. Люди зачастую воспринимаются как безликий материал для достижения государством целей, которые признаны целесообразными его элитами. Но эта небольшая группа людей, бывает, путает интересы государства и свои собственные».`,
    nextPages: ["/doublethink", "/rake"],
    emojies: ['😬', '🗿'],
  },
  "/russian_world": {
    title: "Идея «Русского мира»",
    video: "https://2023art.online/videos/22e5f16d092a06548887b51e1821415a.mp4",
    text: `«Как сказать об империи, о нашем желании расширяться, но при этом не оскорбить слух мирового сообщества, вообще современных людей?»

      С марта 2004 года Владислав Сурков — помощник президента Российской Федерации, курировал вопросы внутренней политики, федеративных и межнациональных отношений. День народного единства — российский государственный праздник, отмечаемый 4 ноября, начиная с 2005 года.`,
    nextPages: ["/victims", "/highest_value"],
    emojies: ['🤨', '😮'],
  },
  "/essential": {
    title: "Тезис о советской политической системе",
    video: "https://2023art.online/videos/903c4078a61df06e34e7617b11cf48e6.mp4",
    text: `«Политические репрессии представляли собой государственный террор против собственного народа, неотъемлемую черту политического устройства СССР, а не отдельные разрозненные случаи злоупотреблений», - Эппле в книге «Неудобное прошлое. Память о государственных преступлениях в России и других странах»`,
    nextPages: ["/terror", "/personally"],
    emojies: ['💔', '🤔'],
  },
  "/terror": {
    title: "Тезис о советской политической системе",
    video: "https://2023art.online/videos/185abbad53eaafb8836ac54fd7af6695.mp4",
    text: `«Террор был важнейшим инструментом целенаправленной политики дезинтеграции общества, приведшей к разрыву горизонтальных связей, всеобщей подозрительности, подавлению инициативы на всех уровнях, боязни высказывать собственное мнение», - пишет автор книги «Неудобное прошлое. Память о государственных преступлениях в России и других странах»`,
    nextPages: ["/doublethink", "/penalty"],
    emojies: ['😥', '👀'],
  },
  "/penalty": {
    title: "Мотивировочная часть",
    video: "https://2023art.online/videos/ac5dd420b535dbb18ea7f65f1e9455fc.mov",
    text: `Призыв давать оценку событиям - наказуем штрафом 30 тысяч рублей.`,
    nextPages: ["/doublethink", "/interests"],
    emojies: ['😥', '🤔'],
  },
  "/kgb": {
    title: "Самое главное, чтобы люди верили",
    video: "https://2023art.online/videos/9520a1db5e799a5c99f69d9b2109f99b.mp4",
    text: `«Огромное число родовых черт советской политической полиции сохраняет ФСБ, сотрудники и руководители которой прямо заявляют, что считают свое ведомство наследником КГБ – НКВД – ВЧК. Это совершенно закрытое ведомство, неподконтрольное ни обществу, ни гражданским властям. Судя по знакам, что время от времени доносятся из этого «черного ящика», и сегодня многие в этой организации, как и в сталинские годы, озабочены не безопасностью страны и соблюдением законов, а борьбой за расширение своего влияния», утверждает автор книги «Неудобное прошлое»`,
    nextPages: ["/personally", "/highest_value"],
    emojies: ['💔', '😬'],
  },
  "/personally": {
    title: "Тезис о советской политической системе",
    video: "https://2023art.online/videos/444672a2d30110b28f6414bb3a94e5d8.mp4",
    text: `По мнению автора книги «Неудобное прошлое. Память о государственных преступлениях в России и других странах», ответственность за репрессии в полной мере лежит на руководстве СССР и лично Ленине и Сталине; система доносительства была результатом кампаний террора, но не их причиной.`,
    nextPages: ["/inefficient", "/mentions"],
    emojies: ['😬', '🥺'],
  },
  "/mentions": {
    title: "Памятники без памяти",
    video: "https://2023art.online/videos/c6da43b918513feb355550bbf4b92111.mp4",
    text: `На 2003 год в России насчитывалось около 1800 памятников Ленину и до 20 000 бюстов, во многих городах его скульптуры возвышаются на центральных площадях; больше 100 памятников Сталину. Возле них не хватает упоминания жертв революции, гражданской войны и Большого террора.`,
    nextPages: ["/2histories", "/privacy"],
    emojies: ['😥', '🤔'],
  },
  "/2histories": {
    title: "Две памяти",
    video: "https://2023art.online/videos/c4a498f04c5a211a9d751b503c4dba8e.mp4",
    text: `Отрывок из книги «Неудобное прошлое»:

      «Историю СССР полезно отмысливать от истории государства (традиционно главного ее составителя, рассказчика и хранителя), стараясь увидеть её как совокупность апроприированных государством частных историй.
      Так как черта под советским прошлым не была подведена, нет и однозначных и общепризнанных интерпретаций этого прошлого. Реальной работы принятия в смысле благодарения за доброе и осуждения преступного проведено не было.

      Формируемая государственной пропагандой модель памяти о триумфальном прошлом страны, единой на всем протяжении ее истории, не ухватывает огромного числа фактов и промахивается мимо значительной части граждан.
      Вторая модель памяти – локальная, свободная от идеологии и сопротивляющаяся ей. Это память отдельных людей об истории их семьи, края, их социальной или национальной общности. Это память, не захваченная государством, через нее возможна личная самоидентификация. В отличие от «большой истории», навязываемой школой, оперирующей давно и широко известными, но мало касающимися учащихся лично фактами, локальная история и история семьи затрагивают человека напрямую. Именно это дает ощущение причастности к истории».`,
    nextPages: ["/schizophrenic_duality", "/final"],
    emojies: ['😥', '😬'],
  },
  "/empty_ideals": {
    title: "Многое нужно сделать для того, чтобы это никогда не забывалось",
    video: "https://2023art.online/videos/a6fdce5ea2b54d65b7cb04cd86205c26.mp4",
    text: `«...привлекательные на первый взгляд, но пустые на поверку идеалы ставились выше основной ценности — ценности человеческой жизни, выше прав и свобод человека. Для нашей страны это особая трагедия. Потому что масштаб колоссальный. Ведь уничтожены были, сосланы в лагеря, расстреляны, замучены сотни тысяч, миллионы человек. Причем это, как правило, люди со своим собственным мнением. Это люди, которые не боялись его высказывать».`,
    nextPages: ["/highest_value", "/russian_world"],
    emojies: ['😥', '👏'],
  },
  "/inefficient": {
    title: "Вопрос об эффективности сталинской экономики",
    video: "https://2023art.online/videos/8009834bdab0e56cb820ec712d0a02e7.mp4",
    text: `Сталинская экономика – мобилизационная индустриализация и зависимость от дешёвого принудительного труда – была неэффективной и в конечном счете обусловила крах СССР. Так считает исследователь Николай Эппле, автор «Неудобное прошлое».
      «Сталинская индустриализация – пример того, как насильственное перераспределение значительно ухудшило производительность и общественное благосостояние» - отмечают авторы экономического исследования «Был ли нужен Сталин для экономического развития России?»`,
    nextPages: ["/empty_ideals", "/highest_value"],
    emojies: ['🥀', '🤨'],
  },
  "/highest_value": {
    title: "Права человека и интересы государства",
    video: "https://2023art.online/videos/ae833be8dc32a7d13dce15e3645c967b.mp4",
    text: `Статья 2 Конституции РФ: Человек, его права и свободы являются высшей ценностью. Признание, соблюдение и защита прав и свобод человека и гражданина - обязанность государства.

      Николай Эппле: «Идеология, на которой строилась советская диктатура – интересы государства важнее жизни отдельного человека, – преступна и не может быть идеологическим основанием современного цивилизованного государства».`,
    nextPages: ["/constitution", "/privacy"],
    emojies: ['☑', '👏'],
  },
  "/privacy": {
    title: "Уход в частную жизнь",
    video: "https://2023art.online/videos/b1cefc93e6104ccb8ce155229681e36b.mp4",
    text: `Нарастающий авторитаризм влечёт за собой внушение гражданам аполитичности. При авторитарной пропаганде от слушателя требуется молчаливое согласие. Такой режим делает всё, чтобы подавить политическую волю граждан, культивируя в людях пассивность и бездействие, чтобы политика не воспринималась гражданами как механизм решения проблем.`,
    nextPages: ["/monopoly", "/final"],
    emojies: ['🤨', '✊'],
  },
  "/justifying_repression": {
    title: "«Сталин недостаточно с нами»",
    video: "https://2023art.online/videos/78134205ea6527f07e81f69386cec7f9.mp4",
    text: `Кто эти 43% опрошенных ВЦИОМ, косвенно или явно оправдывающих репрессии?`,
    nextPages: ["/empty_ideals", "/bestsellers"],
    emojies: ['😥', '🤔'],
  },
  "/bestsellers": {
    title: "Память о советском государственном терроре поразительно двойственна",
    video: "https://2023art.online/videos/fb8cd945ceb90d585690650d84f2da2e.mp4",
    text: `Пермская деловая газета Business Class сообщает:«За последнее время возросла популярность у читателей исторической литературы. В лидерах – книга журналистки Энн Эпплбаум «ГУЛАГ. Паутина Большого террора». Книга в жанре документального исследования рассказывает о политических репрессиях в СССР в XX веке и системе лагерей ГУЛАГа.
    Среди других бестселлеров жанра – «Мобилизованная нация. Германия 1939-1945» профессора истории Оксфордского университета Николаса Старгардта, рассказывающая о Второй мировой войне с точки зрения граждан Германии».`,
    nextPages: ["/inefficient", "/schizophrenic_duality"],
    emojies: ['😥', '🤔'],
  },
  "/schizophrenic_duality": {
    title: "Память о советском государственном терроре поразительно двойственна",
    video: "https://2023art.online/videos/196d47ffe76ebd933a41ec5ddb2d144c.MOV",
    text: `Николай Эппле: «В опросах, выясняющих отношение к Сталину и репрессиям, поразительнее всего даже не масштабы сочувствия тирану, а доля респондентов, считающих, что «массовых репрессий не было». В 2014 году, по данным ФОМ, таких было 16 %, а еще 18 % затруднились с ответом (может, были, может – нет, кто знает).
    По сути, люди, по-разному ответившие на этот вопрос, живут в странах с разной историей. Двойственность в восприятии прошлого ведёт к «шизофренической» двойственности  в настоящем».`,
    nextPages: ["/monopoly", "/final"],
    emojies: ['😥', '🤔'],
  },
  "/monopoly": {
    title: "Монополия на информацию",
    video: "https://2023art.online/videos/544fc14044a93653ac8f1d81ae57e9ac.mp4",
    text: `Роскомнадзор заблокировал 160 тыс. ресурсов с информацией о ходе военной операции на территории Украины с 24 февраля 2022.

      Статья 29 Конституции РФ, пункт 5. Гарантируется свобода массовой информации. Цензура запрещается.`,
    nextPages: ["/final", "/highest_value"],
    emojies: ['🤝', '🤔'],
  },
  "/final": {
    title: "Это конец",
    text: "Если хочется продолжить общение со мной, есть форма обратной связи. Можешь анонимно поделиться со мной своими мыслями, или просто нажать одну из реакций.",
    nextPages: ["/thanks", "/thanks", "/thanks", "/thanks", "/thanks", "/thanks"],
    emojies: ['😡', '🤨', '🤔', '🥺', '👏', '💔'],
    class_name: 'pink'
  },
  "/thanks": {
    title: "Спасибо",
    text: '',
    nextPages: [],
    emojies: [],
    class_name: 'pink'
  },
  "/error": {
    title: "Ой!",
    video: "https://2023art.online/videos/d74707f9de8b9ae728914731d123a265.mp4",
    text: "Похоже, вы попали в историческое небытие. Вернуться в начало:",
    nextPages: ["/"],
    emojies: ['🤔'],
  }
};

const ContentPage = ({ history }) => {
  const location = useLocation();
  const { pathname } = location;
  const currentPageData = pageContent[pathname] || pageContent['/error'];
  const { title, text, video, nextPages, emojies, class_name } = currentPageData;

  const textWithLineBreaks = text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      <p>{line}</p>
    </React.Fragment>
  ));

  function sendMessage(message) {
    const serializedData = {
      'message': message
    };
    const jsonData = JSON.stringify(serializedData);
    const currentDomain = window.location.origin;
    const apiEndpoint = `${currentDomain}/feedback`;

    fetch(apiEndpoint, {
      method: "POST",
      body: jsonData,
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
    }).catch((error) => {
      alert("An error occurred while sending the message");
      console.error(error);
    });
  }

  useEffect(() => {
    const storedVolume = localStorage.getItem("volume") || "0.4";
    const volume = parseFloat(storedVolume);
    const videoElement = document.getElementById("myVideo");
    if (videoElement) {
      videoElement.volume = volume;
      videoElement.muted = localStorage.getItem("muted") === "true";
    }
  }, [location]);

  const saveVolumeLevel = () => {
    const videoElement = document.getElementById("myVideo");
    if (videoElement) {
      localStorage.setItem("volume", videoElement.volume);
      localStorage.setItem("muted", videoElement.muted);
    }
  };

  document.body.className = class_name;
  const button_class = class_name ? 'pink-button' : null;
  const handleButtonClick = (page, index) => {
    if (pathname === '/final') {
      sendMessage(emojies[index]);
    }
    saveVolumeLevel();
    history.push(page);
  };

  return (
    <div className="app-container">
      <h1>{title}</h1>
      {video && (
        <div className="video-container">
          {video.includes('youtube') ? (
            <iframe
              width="960"
              height="540"
              src={video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
          ) : (
            <video
              id="myVideo"
              key={pathname}
              controls
              autoPlay
              loop
            >
              <source src={video} type="video/mp4" />
            </video>)}
        </div>
      )}
      <div className="text_area">{textWithLineBreaks}</div>
      <div className="button-container">
        {nextPages.map((page, index) => (
          <button key={index} className={button_class} onClick={
            () => handleButtonClick(page, index)}>{emojies[index]}
          </button>
        ))}
      </div>
      {pathname === "/" && (
      <div className="footer" style={{ position: 'fixed', right: 0, bottom: 0 }}>
        <a href="https://www.coolagina.art?utm_source=2023art.online" target="_blank" rel="noopener noreferrer">
          <img src="https://2023art.online/artist.png" alt="logo" height={70} />
        </a>
      </div>
      )}
      {pathname === "/final" && (
        <div className="feedback-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const form = e.target;
              const formData = new FormData(form);
              sendMessage(formData.get('message'));
              history.push('/thanks');
            }}
          >
            <label htmlFor="message">Сообщение создательнице проекта</label>
            <br />
            <textarea id="message" name="message" rows="10" cols="50" required=""></textarea>
            <br />
            <input type="submit" value="Отправить" />
            <br />
            <a href="https://www.coolagina.art?utm_source=2023art.online" target="_blank" rel="noopener noreferrer">
              <img src="https://2023art.online/artist.png" alt="logo" height={70} />
            </a>
            <div className="artist-name">Кулагина Анастасия</div>
          </form>
        </div>
      )}
    </div>
  );
};

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          {Object.keys(pageContent).map((path, index) => (
            <Route
              key={index}
              path={path}
              render={(props) => <ContentPage {...props} />}
            />
          ))}
          <Route render={(props) => <ContentPage {...props} />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
